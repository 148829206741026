<template>
    <div>
        <div :class="[isMobile ? 'mobile__content' : 'content']">
            <div :class="[isMobile ? 'mobile-content__about mobile-content__first' : 'content__about content__first']">
                <div class="a_750 title">
                    Skyliner — это самый эффективный агрегатор объявлений о недвижимости
                </div>
            </div>
            <div :class="[isMobile ? 'mobile-content__about' : 'content__about']">
                <div class="a_750 text">
                    Больше не нужно проверять по несколько сайтов с объявлениями в день. Теперь все объявления о недвижимости г.Красноярска находятся в одном месте.
                    <br><br>
                    Мы собираем объявления из таких компаний, как:
                    <br>
                    Авито, Юла, Циан, N1, Яндекс.Недвижимость, Из рук в руки.
                    <br><br>
                    По всем вопросам можете обратиться к создателю сайта, ее контакты находятся ниже.
                </div>
                <div class="first__photo" v-if="!isMobile"></div>
            </div>
        </div>


        <div class="cont__ultimate" :class="[isMobile ? 'mobile-cont__ultimate' : '']">
            <div :class="[isMobile ? 'mobile-content__about' : 'content__about']">
                <div class="title__2">Контакты</div>
                <div :class="[isMobile ? 'mobile-content__about' : 'content__about']">
                    <div :class="[isMobile ? 'mobile-cont__outer__2' : 'cont__outer__2']">
                        <div :class="[isMobile ? 'mobile-cont__inner__2' : 'cont__inner__2']">
                            <div :class="[isMobile ? 'mobile-cont__33' : 'cont__33']">
                                <div class="title__mini">Имя</div>
                                <div class="text__mini">
                                    <a class="url">Юлия</a>
                                </div>
                            </div>
                            <div :class="[isMobile ? 'mobile-cont__33' : 'cont__33']">
                                <div class="title__mini">Почта</div>
                                <div class="text__mini">
                                    <a class="url" href="mailto:y.skultan@gmail.com" target='_blank'>y.skultan@gmail.com</a>
                                </div>
                            </div>

                            <div :class="[isMobile ? 'mobile-cont__33' : 'cont__33']">
                                <div class="title__mini">Телефон</div>
                                <div class="text__mini">
                                    <a class="url" href="tel:+7 (999) 440 79 28" target='_blank'>+7 (999) 440 79-28</a>
                                </div>
                            </div>
                            <div class="cont__33">
                                <div class="title__mini">Телеграм</div>
                                <div class="text__mini">
                                    <a class="url" href="https://t.me/skultan" target='_blank'>@skultan</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.content__about {
    margin: 0 auto;
    max-width: 1200px;
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
}
.mobile-content__about {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.mobile-content__first {
    margin: 15vh auto 0 auto;
}
.content__first {
    margin: 21vh auto 0 auto;
}
.a_750 {
    max-width: 650px;
}
.first__photo {
    width: 490px;
    height: 329px;
    margin-top: -11.5vh;
    background: rgb(240, 240, 240);
    background: url("../assets/about.jpg");
    border-radius: 5px;
    margin-left: auto;
}

.title {
    font: 32px proxima-bold;
    margin-bottom: 30px;
}
.title__2__1 {
    width: 750px;
    font: 26px proxima-bold;
    margin-bottom: 30px;
}
.title__mini {
    font: 18px proxima-bold;
    margin-bottom: 10px;
}

.text {
    font: 17px proxima-regular;
    letter-spacing: 0.025em;
    line-height: 1.45em;
}
.text__mini {
    font: 14px proxima-regular;
    letter-spacing: 0.025em;
    line-height: 1.45em;
}

.cont__outer {
    margin: 0 -20px;
    overflow: hidden;
}
.cont__inner {
    width: 1240px;
    display: flex;
}
.cont__25 {
    width: 25%;
    padding: 0 20px;
}
.cont__ultimate {
    max-width: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 70px 20px 20px 20px;
    background: rgb(244, 246, 250);
}
.mobile-cont__ultimate {
    box-sizing: border-box;
    margin-top: 50px;
    position: relative !important;
}

.title__2 {
    width: 100%;
    text-align: center;
    font: 26px proxima-bold;
    margin-bottom: 30px;
}
.cont__outer__2 {
    margin: 0 -20px;
    overflow: hidden;
}
.mobile-cont__outer__2 {
    width: 100%;
    overflow: hidden;
}
.cont__inner__2 {
    margin: 50px 0;
    width: 1200px;
    display: flex;
    justify-content: space-between;
}
.mobile-cont__inner__2 {
    margin: 50px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cont__33 {
    text-align: center;
}
.mobile-cont__33 {
    margin-bottom: 50px;
    text-align: center;
}
</style>